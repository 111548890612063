"use client"; 

import React, { useState  } from 'react'

import SearchBar from '../navigation/searchBar';


export default function SearchBlock({ stats }) {
  

  // Search Functionality  
  // Search Input controls 
  const [searchText, setSearchText] = useState('')  // SearchText State
  const [searchInputActive, setSearchInputActive] = useState(false)  // Using to keep track if field is focused now or not

  const { countApps=2000, countExternalAutomations=35000 } = stats
  const formatCount = (n) => n.toLocaleString("en-US")
  const [countAppsFormatted, countExternalAutomationsFormatted] = [formatCount(countApps), formatCount(countExternalAutomations)]

  return (
    <>

      <div className=" bg-gradient-to-r from-purple-800 to-crisp-700">
        {/* bg-crisp-500  */}
        <div className="mx-auto max-w-xl lg:max-w-7xl ">
          
          <div className="px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 className="text-center  font-extrabold tracking-tight text-4xl sm:text-5xl lg:text-6xl">
              <p className=" text-white">
                Build and Automate
              </p>

              {/* <span className="block text-white">
                Use no-code
              </span> */}

              <p className="mt-6  text-white">
                Use                 
                <span className="ml-4 before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-amber-500 relative inline-block">
                  <span className="relative text-white">
                    no-code
                  </span>
                </span>                
              </p>                  



              {/* <span className="block text-crisp-200">customer support</span> */}
            </h1>

            <p className="mt-6 max-w-lg mx-auto text-center text-xl text-crisp-200 sm:max-w-3xl">
              <span className='leading-6 font-extrabold text-white'>
                {countAppsFormatted}
              </span>{' '}
              <span>apps</span>{' '}
              <span className='leading-6 font-extrabold text-white'>
                {countExternalAutomationsFormatted}
              </span>{' '}
              <span>automation templates</span>{' '}
            </p>

            {/* <dl className="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-2 sm:gap-8">
              <div className="flex flex-col">
                <dt className="order-2 mt-2 text-lg leading-6 font-medium text-crisp-200">tools</dt>
                <dd className="order-1 text-5xl font-extrabold text-white">1782</dd>
              </div>
              <div className="flex flex-col mt-10 sm:mt-0">
                <dt className="order-2 mt-2 text-lg leading-6 font-medium text-crisp-200">Integrations</dt>
                <dd className="order-1 text-5xl font-extrabold text-white">33,639</dd>
              </div>
            </dl> */}

            <SearchBar 
              placeholder='Enter tool name ...' 
              searchText={searchText}
              setSearchText={setSearchText}
              showSearchButton={false}
              classContainer='relative mt-10 sm:mx-auto sm:max-w-lg sm:flex '
              classInput=" block w-full border border-transparent rounded-md py-3 pr-3 pl-9 text-lg text-gray-900 placeholder-gray-400 shadow-sm outline-none focus:ring-1 focus:ring-amber-500 focus:border-amber-500"              
              // classInput='block w-full border border-transparent rounded-none rounded-l-md px-5 pr-3 pl-9 text-lg text-gray-900 placeholder-gray-400 shadow-sm outline-none focus:ring-1 focus:ring-amber-500 focus:border-amber-500' // with button
              classDropdownContainer='z-10  mt-16 bg-white  shadow overflow-auto rounded-md  max-h-56 absolute inset-x-0 top-0 border-amber-500'
            />

            
          </div>

        </div>
      </div>

    </>
  )
}