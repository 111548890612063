

export function getStrapiURL(path="") {
  return `${
    // process.env.NEXT_PUBLIC_STRAPI_API_URL || "http://localhost:1337"
    process.env.NEXT_PUBLIC_STRAPI_API_URL || 'https://api.stackreaction.com'
  }${path}`; 
  
}
  
// Helper to make GET requests to Strapi
export async function fetchQuery(path) {
  const requestUrl = getStrapiURL(path);
  const response = await fetch(requestUrl);
  const data = await response.json();
  return data;  
}


const authenticateCM = async (identifier, password) => {
  // Authenticate to Strapi 
  const response = await fetch(getStrapiURL("/auth/local"), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ identifier, password})  
  })

  const data = await response.json();
  return data.jwt
  // todo: no error handling 
}; 


export async function fetchCMQuery(path) {
  // Fetch Query Authenticated as Content Manager  
  const requestUrl = getStrapiURL(path);
  const jwt = await authenticateCM(process.env.STRAPI_EMAIL, process.env.STRAPI_PASSWORD); 

  const response = await fetch(requestUrl, {
    method: 'GET', 
    headers: { 'Authorization': `Bearer ${jwt}` },
  })

  const data = await response.json();
  return data  
  // todo: no error handling 
}



export const apiErrors = (code) => {
  if (code === 500) {
    return {    
      statusCode: 500,
      error: 'Internal Server Error',
      message: 'Internal Server Error'  
    }
  } else if (code === 401) {
    return {
      statusCode: 401,
      error: 'Unauthorized',
      message: 'Unauthorized'        
    }
  }
}