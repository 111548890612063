"use client";

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'

import { toast } from 'react-toastify'

import logger from '../../../lib/logger' 
import { rgx, classNames } from '../../../lib/helpers' 
import { getStrapiURL } from '../../../lib/api'



export default function LandingForm({ formClass, buttonClass, onSuccess=()=>{} }) {
  const logPx = 'Component.LandingForm'
  logger.info("Component.LandingForm rendering.");



  const { register, handleSubmit, watch, formState, clearErrors, setError, reset } = useForm({ mode: 'onSubmit' });
  const { isDirty, isValid, isSubmitting, errors } = formState;
  const [isFormLoading, setIsFormLoading] = useState(false);  // To disable Button while loading
    
  // const notify = () => toast("Wow so easy !");

  const onSubmit = data => {
    logger.info(`${logPx} Form Submitted with data: ${JSON.stringify(data)}`)
    setIsFormLoading(true);

    const { email } = data
    const submitData = { submission: { email }, type: 'preregistration' }    

    axios({
      method: 'POST',
      url: getStrapiURL('/form-submissions'),
      data: submitData,
      // headers: { 'Authorization': `Bearer ${Cookies.get("token")}` }
    }).then(response => {
        logger.info(`${logPx} Form Response data: ${JSON.stringify(response?.data)}`)
        setIsFormLoading(false);
        reset();
        onSuccess();
        toast.success("Great! You're on the list!");
      }).catch((error) => {
        logger.info(`${logPx} Form Response Error: ${error}`)
        setIsFormLoading(false);      
        toast.error("Something went wrong!");
      });

  };

  // UI
  const inputClass = (isError) => {
    return classNames(
      isError
        ? 'text-red-900 placeholder-red-500 border-red-300 '
        : 'text-gray-900 placeholder-gray-500 border-gray-300 ',
      'block w-full rounded-md shadow-sm border  px-5 py-3 md:py-4 text-base md:text-lg font-medium focus:outline-none'
    )    
  };   


  return (
    <>
      
      <form className={formClass} method="POST"
            onSubmit={handleSubmit(onSubmit)}
      >

          <div className="min-w-0 flex-1">
            <label htmlFor="email" className="sr-only">
              Email address
            </label>
            <input {...register("email", { required: "Email is required", pattern: {value: rgx.email, message: "Email should be valid"} })} 
              // type="email"
              autoComplete="email" 
              placeholder="Enter your email"
              className={inputClass(errors.email)}
            />          
          </div>
          <div className='mt-3 sm:mt-0 sm:ml-3'>
            <button
              type="submit"
              disabled={isSubmitting}
              className={classNames(isFormLoading ? 'animate-pulse' : '', buttonClass)}
            >
              Get Early Access           
            </button>
          </div>

      </form>

    </>
  )
}